import React from "react"
import { Link } from "gatsby"
import ReadingIcon from "../../static/icons/reading-light.svg"
import GroupIcon from "../../static/icons/group-large.svg"
import ActivityIcon from "../../static/icons/activity-light.svg"
import VideoIcon from "../../static/icons/video-light.svg"
import AssessmentIcon from "../../static/icons/assessment-light.svg"
import CheckpointIcon from "../../static/icons/checkpoint-light.svg"

function Page(props) {
  let { page } = props;
  let typeEmoji = "";
  let title = "";
  let statusEmoji = "";
  let pageLink = page.path + "/index.html";

  switch(page.type) {
    case "READING":
      typeEmoji = ReadingIcon;
      title = `Reading - ${page.title}`
      break;
    case "CHECKPOINT":
      typeEmoji = CheckpointIcon;
      title = `Checkpoint - ${page.title}`
      break;
    case "VIDEO":
      typeEmoji = VideoIcon;
      title = `Video - ${page.title}`
      break;
    case "ACTIVITY":
      typeEmoji = ActivityIcon;
      title = `Activity - ${page.title}`
      break;
    case "GROUPWORK":
        typeEmoji = GroupIcon;
        title = `Group Work - ${page.title}`
        break;
    case "ASSESSMENT":
      typeEmoji = AssessmentIcon;
      title = `Assessment - ${page.title}`
      break;
    case "STRATEGY":
      typeEmoji = ReadingIcon
      title = `Strategy - ${page.title}`
      break;
    case "MODULE_OVERVIEW":
      typeEmoji = ReadingIcon
      title = `Module Overview - ${page.title}`
      break;
    case "SUBMODULE_OVERVIEW":
      typeEmoji = ReadingIcon
      title = `Submodule Overview - ${page.title}`
      break;
    case "PROJECT":
      typeEmoji = AssessmentIcon
      title = `Project - ${page.title}`
      break;
    default:
      typeEmoji = ReadingIcon
      title = page.title;
      break;
  }

  switch(page.status) {
    case "DONE":
      statusEmoji = "✅"
      break;
    case "REVIEW":
      statusEmoji = "📝"
      break;
    case "IN_PROGRESS":
      statusEmoji = "💭"
      break;
    case "OUTLINED":
      statusEmoji = "📋"
      break;
    case "UNDEFINED":
      statusEmoji = "❓"
      break;
    case "REMOVED":
      statusEmoji = "❌"
      break;
    default: 
      statusEmoji = "❓";
      break;
  }

  let typeImageStyle = {
    marginBottom: "0",
    width: "24px",
    height: "24px"
  }

  let statusImageStyle = {
    ...typeImageStyle,
    marginRight: "0.5em"
  }

  let githubLink = "";
  if (page.github) {
    githubLink = (
      <h6 style={{margin: 0, marginBottom: "0.5em"}}>
        <span role="img" aria-label="link">🔗</span> <a href={page.github}>{page.github}</a>
      </h6>
    )
  }

  let contentCardStyle = {
    background: "white",
    borderRadius: "0.5em",
    width: "100%",
    display: "flex",
    overflow: "hidden",
    minHeight: "40px",
    boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.3)"
  };

  let typeStyle = {
    padding: "0.1em",
    background: "#1D3868",
    flexBasis: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }

  let titleStyle = {
    padding: "0.2em 0.5em",
    flexGrow: 1,
    lineHeigth: "24px"
  }

  let statusStyle = {
    padding: "0.1em",
    flexBasis: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }

  return (
    <div style={contentCardStyle}>
      <div style={typeStyle}>
        <img src={typeEmoji} alt={"Type - " + page.type} style={typeImageStyle}/>
      </div>
      <div style={titleStyle}>
        <h4 style={{margin: 0, lineHeight: "40px"}}>
          <Link to={pageLink}>{title}</Link>
        </h4>
        {githubLink}
      </div>
      <div style={statusStyle}>
        <img src={"https://emoji.beeimg.com/" + statusEmoji + "/48/twitter"} alt={statusEmoji} style={statusImageStyle}></img>
      </div>
    </div>
  )
}

export default Page